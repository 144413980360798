
/* ---=== -- Blue Steel Theme -- CSS -- --==-- */
@import "bsColors";

@keyframes bgMove-x {
	from {background-position-x: 0;}
	to {background-position-x: 500px;}
}
@keyframes rotate360 {
	from {transform: rotate(0deg);}
	to {transform: rotate(1449deg);}
}


/* === Home Theme Styles SCSS == */
.front .row .l-region--content  {
	.block.block-views-news-and-event-slider-block-1.news-event-slider-block,.block.block-views-news-and-event-slider-block.news-event-slider-block	{
		.view-content .item-list ul li.views-row .article {
			h3.title a  {
				font: {
					weight: 400;
					size: 17px;
				}
			}
			.meta {
				.links {
					a {
						font: {
							family: roboto, $smallFont;
							weight: 400;
							size:11px;
						}
						letter-spacing: 0.8px;
						padding:0;
					}
				}
				p.post-date {
					font: {
						weight: 400;
						size: 12px;
					}
				}
			}
		}		
	}
}

a, a:hover, a:link, a:active, a:visited {
	font: {
		family: $linkFont;
		weight: 500;
	}
}

.mega-menu-toggle-wrapper .block-content a  {
    display:none;
}

a:active {
    background-color: inherit;
}

.front .layout .flexible-row  {
	.block-title,  .view-header  h3, .view-header  h3 a {
		width: auto;
		font: {
			size: 32px;
			family: $headingFont;
			weight: 300;
		}
		text-align: center;
		color: $drab;
		text-transform: uppercase;
		letter-spacing: 0;
	}
	.row {
		.col-md-12,  .col-md-12 .row {
			padding: 0;
			margin-left: 0;
			margin-right: 0;
		}
	}
	.view-header  {
		h3 {
			margin: 40px auto 6px;
		}
		p {
			margin: 0;
			padding: 0;
		}
	}
	/* +++++++++++==|||=== home Hero Block -- MOBILE 1st -- ===|||===+++++++++++++++  */
	.no-container.hero-block .row {	
		.l-col.col-md-12.l-region--hero-block {
			display: grid;
			grid-template-areas:
				'cell1'
				'cell2'
				'cell3'
				'cell4'
				'cell5';
			grid-template-columns: 100%;
			margin-left: 0;
			margin-right: 0;
			padding: {
				right: 0;
				left: 0;
			}
			
			.block {
				padding-left: 0;
				padding-right: 0;
			}
			.block.block-block-product-category-mega-menu-toggle-wrapper.mega-menu-toggle-wrapper {
				grid-area: cell1;
			}
			.block.block-views-product-category-mega-menu-block-level-0.mega-menu {
				grid-area: cell2;
			}
			.block.block-views-home-page-slider-block-7 {
				grid-area: cell3;
			}
	/*--+++++++|||+++++--- MainCats Block homeHero -- MOBILE 1st -- -++++(%)+(#3)++++||+++++++-- */
			.block.block-views-term-ref-categories-block-6 {
				grid-area: cell4;		
				margin-bottom: 0px;
				margin-top: 12px;

				.view-term-ref-categories {
					.view-header {
						flex-wrap: wrap;
						display: flex;
						justify-content: center;
						position: relative;
						
						h3 {
							font: {
								family: $headingFont;
								weight: 400;
								size: 18px;
							}
							padding-left:10%;
							padding-right:10%;
							text-align: center;
							margin: 40px auto 6px;
						
							a {
								text-decoration: none;
								position: relative;
								display: block;
								z-index: 2;
								border-radius:	100px;
								transition: .4s ease;
								letter-spacing:0;
							}
							a::before {
								width: 10px ;
								height: 10px;
								display: block;
								content: "";
								position: absolute;
								border: {
									color: #87bcbf00;
									width: 2px 2px 0 0;
									style: solid;
								}
								transform: rotate(45deg) translateY(-50%);
								right: -1%;
								top: 50%;
								margin-top: -1px;
								transition: all ease 0.4s;
							}	
							a:hover {
								text-decoration: none;
								letter-spacing: 1.2px;
								color: $steelBluegreen;
								transition: all ease 0.2s;
							}
							a:hover::before, a:active::before {
								right: -20px;
								border-color: $sage;
								transition: all ease 0.2s;
							}
							a::after {
								@include hovUnderOff;
							}
							a:hover::after, a:active::after{
								@include hovUnderOn;
							}
						}
					}
					.view-content {
						background-color: $onyx;
						padding: 0 4%;
						
						.views-view-grid::before {
							display: none;
						}
						.views-view-grid {
							display: grid;
							grid: {
								template: {
									columns: 1fr;
									rows: repeat(5, 90px) 0;
								}
								row-gap: 20px;
							}
							padding: 20px 0 0;
							background-color: $drab;
							min-width: 0px;
							
							.views-grid-box  {
								padding: 0;
								position: relative;
								background-color: $steelBlueLte;
								margin: 0 0 0 -4%;
								height:100%;
								width: 100vw;
								border: {
									style: solid;
									width: 3px;
									color: $steelBluegreen;
									left: none;
									right: none;
								}
								box-shadow: -1px 5px 12px -2px #2222228c;
								transition: all ease 0.5s;
								
								.field-content {
									text-align: center;
									width: 100%;
									display: block;
									width: auto;
									height: auto;
									overflow: initial;
									box-shadow: none;
									margin: auto;
								
									a {
										display: grid;
										position: relative;
										grid: {
											template: {
												columns: 2fr 3fr 1fr;
												rows: 90px;
											}
										}
										text-decoration: none;
										align-items: center;
																
										.logo-image {
											width: 100%;
											height: 100%;
											background: linear-gradient(95deg, #C8D1D3, #E1E7EA, #F0F3F4, #F8F8F8, #fff, #fff, #fff), #fff;
											border: {
												style: solid;
												width: 3px 3px 3px 0;
												color: $steelBlueDrk;
												radius: 0 100px 100px 0;
												left:0;
											}
											margin-top: -6px;
											box: {
												sizing: border-box;
												shadow: 2px 4px 11px -2px #00000080;
											}
											overflow: hidden;
											transition: .15s ease;
		
											img {
												display: block;
												background-color: unset;
												height: 100%;
												width: auto;
												float: right;
												mix-blend-mode: multiply;
											}
										}
										.cat-name {
											.fullTitle, .shortTitle {
												align-self: center;
												font: {
													family: $linkFont;
													weight: 800;
													size: 18px;
												}
												color: $onyx;
												letter-spacing: 2px;
												margin-top: -3px;											
												transition: .4s ease;
											}
											.shortTitle {
												display:none;
											}
										}
									}
									a:hover, a:active {
										.logo-image {
											width: 120%;
											padding-right: 10%;
											transition: .3s ease;
										}
										.cat-name {
											.fullTitle, .shortTitle {
												color: $ice;
												text-shadow: 3px 4px 3px #000;
											}
										}
									}
								}
							}
							.views-grid-box:hover {
								background-color: #261a0c; /* #21282d */
								border-color: $rust;
							}
						
							.views-grid-box.views-grid-box-6.even.last.row-last {
								display: none;
							}
						}
					}
				}
			}
			.block.block-block-product-finder {
				grid-area: cell5;
				
				.block-content .product-finder-btn.btn {
					a {
						display: block;
						position: relative;
						margin: 40px auto;
						width: 300px;
						text: {
							align: center;
							decoration: none;
							transform: uppercase;
						}
						font: {
							family: $headingFont;
							weight: 300;
							size: 22px;
						}
						letter-spacing: 2.2px;
						color: $steelBluegreenLte;
						line-height: 1.4;
						transition: .6s ease;
						
						.btn-inner {
							padding:  10px 20px;
							background-color: #314653;
							border-radius: 100px;
							text-shadow: 1px 1px 1px $steelBluegreen;
							transition: .8s ease;
						}
					}
					a:hover, a:active {
						color: $ice;
						letter-spacing: 3px;
						transition: .3s ease;
						
						.btn-inner {
							background-color: #195f8b;
							text-shadow: 1px 1px 3px #006585;
							transition: .45s ease;
						}
					}
					a:active { 
						color: #fff;
						transition: .8s ease .9s;
					}
				}
			}
		}
	} /* --------|||-  ++++ == End Hero Block +++++ - - -- MOBILE 1st -- ========= */
	.container-fluid.region_12 .l-flexible-row.row {
		margin-right: -0.9375rem;
		margin-left: -0.9375rem;
	}
	.container-fluid .row {
		.l-region--content {
/* -======---Shelving Systems----|||- ========== */
			.home-shelving-system {
				margin-bottom: 30px;
				
				ul li , ul {
					list-style-type: none;
					margin: 0;
					padding: 0;
				}
				.field-content .row {
					width: 100%;
					display: grid;
					grid-template-rows: 180px auto;
					background-color: $sage;
					
					.col-6 {
						width: 100%;
						height:100%;
						overflow: hidden;
						
						p {
							margin-top:0;
							margin-bottom:0;
							text-align: center;
						}
						img {
							display: block;
							min: {
								width: 100%;
								height: 100%; 
							}
							max: {
								width: unset;
								height: unset;
							}
							height: 124%;
							width: auto;
						}
						.text-line-1 {
							color: $onyx;
							font-size: 18px;
							margin: 0;
							font: {
								family: $bodyFont;
								weight: 300;
							}
							order:2;
						}
						.text-line-2 {
							color: $drab;
							font: {
								weight: 800;
								family: $headingFont;
								size: 38px;
							}
							text-transform:uppercase;
							line-height: 1.1;
							margin: 0 0 7px 0;
							order:1;
						}
						.text-line-3 {
							color: $onyx;
							font: {
								size: 14px;
								family: $bodyFont;
								weight: 400;
							}
							margin: 0 auto 5px;
							order:5;
						}	
						
						@import "btn_onyxBg-sageTxt";
					}
					.col-6:nth-child(2) {
						display: flex;
						flex-flow: column;
						flex-direction: column;
						width: 100%;
						padding: 20px;
						box-sizing: border-box;
					}
				}
			}

/* ++==|||==== Quicklinks -- MOBILE 1st -- ---+++++=======++ */
			.block.block-views-home-page-slider-block-2
			.home-quicklinks {
				margin-bottom: 30px;
			
				.view-content {
					padding: 20px 10px;
					background-color: $ice2;
					
					.views-view-grid {
						display: grid;
						grid-template-columns: 1fr;
						grid-auto-rows: 118px;

						.views-grid-box {
							width: 100%;
							height:100%;
							border: none;
							padding: 10px 12px;
							transition: all ease 0.5s;
							
							span.field-content, .views-field.views-field-nothing  {
								height: 100%;
							}
							.quicklinks-item {
								display: grid;
								grid-template-columns: 1fr 1fr;
								align-items: center;
								padding: 6px;
								background-color: $sage;
								box-shadow: 2px 2px 8px -2px #4a5356d1;
								border-radius: 8px;
								height:100%;
								
								.quicklinks-image {
									margin:0;
									height:100%;
									
									a {
										position: relative;
										box-shadow: none;
										display: block;
										padding: 0;
										overflow: hidden;
										background-color: transparent;
										height: 100%;
										
										img {
											position: absolute;
											top: 50%;
											left: 50%;
											transform: translate(-50%, -50%);
											max-height: 100%;
											max-width: 80%;
											width: auto;
										}
									}
								}
							
								.quicklinks-txt a {
									display: block;
									font: {
										family: $linkFont;
										weight: 600;
										size: 22px;
									}
									color: $onyx;
									line-height:1.1;
									text: {
										decoration: none;
										align: center;
									}
									letter-spacing: 1.2px;
									max-width: 124px;
									margin: auto;
									
								}
							}
							.quicklinks-item.ql-products {
								background-color: $linkBlue1;
							}
							.quicklinks-item.ql-lte {
								background-color: $steelBlueDrk;
							}
							.quicklinks-item.ql-support {
								background-color: $sage;
							}
							.quicklinks-item.ql-videos {
								background-color: $rustBtn;
							}
							.quicklinks-item.ql-green {
								background-color: $sage2;
							}
							.quicklinks-item.ql-contact {
								background-color: $steelBluegreenLte;
							}
						}
					}
				}
			}
		}
	}
}

/* =###== Tiny Screens =###=== */
@media (max-width: 299px) {
	.front .row .l-region--content {
		.home-shelving-system {
			.field-content .row {
				.col-6 {
					img {
						min: {
							width: 100%;
							height: 100%; 
						}
						max: {
							width: unset;
							height: unset;
						}
						height: 124%;
						width: auto;
					}
					.text-line-1 {
						font: {
							size: 14px;
							weight: 600;
							family: $labelFont;
						}
						margin: 8px 15px 6px;
						line-height: 1.2;
					}
					.text-line-2 {
						font-size: 24px;
						margin: 0 5px 7px;
					}
					.button-link-wrap {
						padding: 15px 0;
						
						a {
						    padding: 8px 14px;
							font-size: 15px;
							line-height: 1;
						}
					}
				}
				.col-6:nth-child(2) {
					padding: 20px 3%;
				}
			}
		}
	}	
}/* =###== END max 299 - Tiny Screens -END- =###=== */



/* =========================== Media Query min-width 494 ==================== */
@media (min-width: 494px) {
	.front .layout .flexible-row {
		.no-container.hero-block .row .l-col.col-md-12.l-region--hero-block {
			.block.block-views-home-page-slider-block ul.slick-slider {
				li.views-row.slick-slide {
					.field-content {
						.row {
							display: grid;
							grid-template-rows: 240px 285px;
						}
					}
				}
			}
		}
		
		.row .l-region--content {

			.home-shelving-system {
				.field-content {
					.row {
						grid-template-rows: 235px auto;
						
						.col-6 {
							.button-link-wrap {
								min-width: 260px;
								text-align: center;
								font-size: 18px;
								padding: 0;
								margin: 14px auto;
							}
						}
						.col-6:nth-child(2) {
						
						}
					}
				}
			}
		}
	}
} /* == -END- ======= min-width 494 ====== -END- ===== */


/* =========================== Media Query min-width 660 ==================== */
@media (min-width: 660px) {
	.front .layout .flexible-row {
	/*--+++++++|||+	homeHero -- 660 --+++++-- */	
		.no-container.hero-block .row .l-col.col-md-12.l-region--hero-block {
			
			.block.block-views-term-ref-categories-block-6 {
				.view-term-ref-categories .view-header {
					justify-content: flex-start;
					
					h3 {
						padding-left:5%;
						margin: 40px 0 6px;
						font-size: 32px;
					}
				}
			}
/*--+++|||+++++--- Slider Block homeHero -- 660 -- -++(%)+(#4)++6+++++-- */
			.block.block-views-home-page-slider-block {			
				ul.slick-slider {
					li.views-row.slick-slide {
						.field-content {
							.row {	
								display: grid;
								grid-template: {
									columns: 50% 50%;
									rows: 280px;
								}
								height: 100%;							
								
								.col-6:nth-child(1){
									width: 100%;
									
									h2.text-line-1 {
										font: {
											weight: 700;
											size: 28px;
										}
										letter-spacing:0;
										line-height: 1.1;
										margin:0 0 4px 10px;
										text-align: left;
									}
									p.text-line-2 {
										font: {
											weight: 400;
											size: 14px;
										}
										margin: 0 0 5px 0;
										line-height: 1.2;
										text-align: left;
										padding-left: 40px;
									}
									p.text-line-3 {
										font-size: 22px;
										margin: 0 0 4px 0;
										line-height: 1;
										text-shadow: 1px 1px 2px #000;
										flex: 1;
									}
									p.text-line-4 {
										margin: 5px 0 4px 0;
										line-height: 1.2;
										flex: 1;
									}
									p.text-line-5 {
										margin: 0 0 12px 0;
										font-size: 16px;
										font-weight: 500;
										line-height:1;
									}
								}
								.col-6:nth-child(1) {
									order: 1;
									padding:36px 15px;
								}
								.col-6:nth-child(1)::after {
									height: 60%;
									bottom: 0;
								}
								.col-6:nth-child(2) {
									width: 100%;
									height: 100%;
									overflow: hidden;
								}
							}
						/*--### Slide Name -- Phenolic -- ## -- 660 ###-- */
							.row.phenolic {
								background-color: $drab;
								.col-6 {
									img {
										position: absolute;
										left: auto;
										right: 0;
										transform: translate(0%, -50%);
										min: {
											width: 100%;
											height: 100%; 
										}
										max: {
											width: unset;
											height: unset;
										}
										height: 124%;
										width: auto;
									}
								}
								.col-6:nth-child(1) {
									padding: 16px 14px;
									
									p.text-line-2 {
										padding-bottom: 0;
										font-size: 14px;
									}
									p.text-line-3 {
										font-size:16px;
										margin: 0;
										line-height: 1;
										text-shadow: 1px 1px 2px $onyx;
										flex: 1;
									}
									p.text-line-4 {
										margin: 5px 0 4px 0;
										line-height: 1.2;
										flex: 1;
									}
									p.text-line-5 {
										margin: 6px auto;
										flex: 1;
									}
								}
							}
						/*--### Slide Name -- browse-products -- 660 ###-- */
							.row.browse-products.home-slider {
								background-color: $drabber2;
								
								.col-6:nth-child(1)::after {
									background: {
										image: url('../images/arrows-bg.png');
										position-y: center;
										position-x: 0;
										repeat: repeat-x;
										size: contain;
									}
								}
								.col-6:nth-child(1) {
									text-align: center;
									padding: 20px 12px;
								
									p {
										text-align: center;
										color: $ice;
									}
									h2.text-line-1 {
										font: {
											family: $headingFont;
											weight: 500;
										}
										margin: {
											left: auto;
											right: auto;
										}
										color: $steelBluegreen;
										letter-spacing: .9px;
										text-transform: uppercase;
									}  
									p.text-line-2 {
										text-align: center;
										padding: 6px 0;
									}
								}
							}
						/*--### Slide Name -- browse-lockers -- 660 ###-- */
							.row.browse-lockers.home-slider {
								background-color: $onyx;
								
								.col-6:nth-child(1)::after {
									background: {
										position-y: center;
										position-x: 0;
										repeat: repeat-x;
										size: contain;
									}
								}
								.col-6:nth-child(1) {
									text-align: center;
									padding: 10px 15px;
								
									p {
										text-align: center;
										color: $ice;
									}
									h2.text-line-1 {
										font: {
											family: $headingFont;
											weight: 500;
											size: 48px;
										}
										color: $steelBluegreenLte;
										letter-spacing: .9px;
										text-transform: uppercase;
									}  
									p.text-line-2 {
										text-align: center;
										padding-left: 0;
									}
									p.text-line-3 {
										color: $sage;
										font: {
											size: 16px;
											weight: 100;
										}
										padding: 1px 0 2px;
										text-transform: capitalize;
									}
									p.text-line-4 {
										font: {
											family: $bodyFont;
											weight: normal;
											size: 14px;
											style: oblique;
										}
										text-transform:capitalize;
										color: $ice;
										padding: {
											top: 6px;
											bottom: 8px;
										}
										
									} 

									p.text-line-5 {
										color: $steelGray;
									}
								}
							}
						}
					}
				}
			}
		} /*--------|||+	END END END homeHero -- 660 ----===-- */
		
/*--+++++++||| Begin Content Region -- 660 --+++++-- */
		.row {
			.block-title, .view-header h3 {
				font-size: 32px;
				font-family: $headingFont;
				font-weight: 200;
				color: $drab;
				text-transform: uppercase;
				text-align:left;
			}
			
			.view-header h3.block-title {
				padding-left: 5%;
				margin-top: 32px;
			}
			.view-header h3 a  {
				display: inline-block;
			}
		}
		.container-fluid .row .l-col.col-md-12.l-region--content {		
			.view-header h3 {
				margin-left: 5%;
			}
			.block.block-views-home-page-slider-block-1 .block-content {
				.view-home-page-slider.home-shelving-system .view-content .item-list {
					.field-content .row.shelvingSystems-block {
						grid-template-columns: 50% 50%;
						grid-template-rows: 260px;
							
						.col-6:nth-child(1) {
							position: relative;
							
							img {
								@include centerImg;
								display: block;
								width: auto;
								height: auto;
								min: {
									width: 110%;
									height: 130%;
								}
								max: {
									width: 180%;
									height: unset;
								}
							}
						}
						.col-6:nth-child(2) {
							display: grid;
							position: relative;
							grid-template-areas: 'line1'
												'line2'
												'buttonLnk'
												'line3'
												'line4'
												'line5';
							padding-top: 14px;
							
							.text-line-1 { 
								grid-area: line1;
								padding-bottom: 12px;
							}
							.text-line-2 {
								grid-area: line2;
								font-size: 32px;
								line-height:1;
							}
							.text-line-3 {
								grid-area: line3;
							}
							.text-line-4 {
								grid-area: line4;
							}
							.text-line-5 {
								grid-area: line5;
							}
							.button-link-wrap {
								grid-area: buttonLnk;
								padding: 4px 15px;
								
								a {
									font-size: 16px;
									font-weight: 500;
									padding: 3px 30px 1px;
								}
								a::after {
									width: 8px;
									height: 8px;
									
								}
							}
						}	
					}
				}
			}
			.block-views-home-page-slider-block-2 .home-quicklinks .view-content {
				 padding: 20px 3.5%;
				
				.views-view-grid {
					grid-template-columns: 1fr 1fr;
					grid-auto-rows: 110px;
				
					.views-grid-box .quicklinks-item.ql-support a img {
						top: 54%;
					}
					.views-grid-box {
						.quicklinks-item.ql-support a img {
							top: 54%;
						}
						.quicklinks-item.ql-videos a img {
							top: 47%;
						}
					}
				}
			}
		}/*----==--||| END END END Content Region -- 660 ----==-- */
	}
} /*--+++++++|||+	END END END mediaQuery -- 660 --+++++-- */

/* =========================== Media Query min-width 768 ==================== */
@media (min-width: 768px) {
	.front .layout .flexible-row  {
		
/* +++++++++++==|||=== home Hero Block -- iPad1 ++-- ===|||===+++++++++++++++  */
		.no-container.hero-block .row {
			.l-col.col-md-12.l-region--hero-block {
				grid-template-areas:
					'..... cell3 cell1'
					'cell2 cell2 cell2'
					'cell4 cell4 cell4'
					'..... cell5 .....';
				grid-template-columns: 1fr 4fr 1fr;
				padding:0;

/* ++++++++++==|||=== CATS -(reg-HERO)- iPad1 ++-- ===|||==+++  */				
				.block.block-views-term-ref-categories-block-6 {	
					margin-bottom: 30px;
					margin-top: 12px;
					padding-right: 0;
					padding-left: 0;
					width: 100%;
			
					.block-content .view.view-term-ref-categories.view-id-term_ref_categories.view-display-id-block_6.mainCats {
						
						.view-content {
							background-color: transparent;
							padding: 0 20px;

							.views-view-grid  {
								position: relative;
								grid: {
									column-gap: 2%;
									template-columns: repeat(5, 1fr);
									template-rows: auto;
									row-gap: unset;
								}
								padding:0 20px;
								width:auto;
								-ms-grid-columns: (1fr)[5];
								background-color: transparent;
								
								.views-grid-box.main-cats-top {
									box-shadow: none;
									border: none;
									margin:0;
									padding: 0;
									background-color: transparent;
									width: auto;
									
									.views-field-name {
										position: relative;
											
										span.field-content {
											background-color: transparent;
											text-align: center;
											width: 100%;
											display: block;
											
											a {
												display: block;
												perspective: 300px;
													
												.logo-image {
													position: relative;
													border-radius: 100px;
													border: none;
													height: 16vw;
													width: 16vw;
													margin: auto;
													padding: 0;
													box-shadow: 3px 3px 12px -5px #312e3e2b;
													transition: .6s;
													overflow: hidden;
													background: radial-gradient(#fff, #fff, #fff, #f0f3f482, #FFF), #fff;
													
													img {
														display: block;
														margin: auto;
														@include centerImg;
														border-radius: 100%;
														background-color: transparent;
														max-width: 78%;
														max-height: 90%;
														height: auto;
														transform-origin: left top;
														transition: {
															duration: .45s;
															timing-function: cubic-bezier(0.54, -0.13, 0.28, 2.15);
														}
													}
												}
												.cat-name {
													.fullTitle, .shortTitle  {
														margin-top: 0;
														padding-top: 17px;
													}
												}
											}
											a:hover , a:active {
												.logo-image {
													perspective: 300px;
													scale: 1.15;
													box-shadow: 8px 11px 26px -5px #312e3e2b;
													transition: {
														duration: .6s;
														timing-function: cubic-bezier(0.54, -0.13, 0.37, 1.51);
													}
													
													img {
													    transition: {
															duration: .6s;
															timing-function: cubic-bezier(0.54, -0.13, 0.37, 1.51);
														}
														scale: 1.25;
													}
												}
												.cat-name {
													.fullTitle, .shortTitle  {
														margin-top: 0;
														color: #006585;
														text-shadow: 2px 6px 4px $sage;
														transition: .5s ease;
													}
												}
											}
										}
									}
								}
								.views-grid-box.views-grid-box-6.even.last.row-last {
									display: block;
								}
								.views-grid-box.views-grid-box-2 a .cat-name {
									.fullTitle {
										display: none;
									}
									.shortTitle {
										display: block;
									}
								}
								.views-grid-box.views-grid-box-1.odd.first.row-first img {
									padding-top: 9%;
									padding-bottom: 9%;
								}
							}
						}
					}
				}
			}
		} /* ## END END END -(region HERO)- iPad1 --==|||==## */
		.row {
			.col-md-12 {
				padding: 0;
				margin-left: 0;
				margin-right: 0;
			}
		
			.block-title, .view-header h3.block-title {
				font-size: 28px;
			}
		}
		.container-fluid .row {
/* ++++++++++==|||=== BEGIN - (reg-CONTENT)-- iPad1 ++-- ===|||==+++  */
			.l-col.col-md-12.l-region--content {

/* ++++++++++==|||=== SHELVING SYSTEMS - (reg-CONTENT)-- iPad1 ++-- ===|||==+++  */
				.block {
					margin-bottom: 30px;
				}
				.block.block-views-home-page-slider-block-1 .block-content .view-home-page-slider.home-shelving-system {
					.view-content .item-list .field-content {
						.row.shelvingSystems-block {
							grid-template: {
								columns: auto 380px;
								rows: 280px;
							}
							.col-6 {
								.text-line-1 {
									font-size: 22px;		 
								}
								.text-line-2 {
									font-size: 50px;
								}
							}
							.col-6:nth-child(1) {
								img {
									min: {
										width: 100%;
										height: 100%; 
									}
									max: {
										width: unset;
										height: unset;
									}
									height: 124%;
									width: auto;  
								}
							}
						}
					}
				}
/* ++++++++++==|||=== QUICKLINKS - (reg-CONTENT)-- iPad1 ++-- =|||=+  */
				.home-quicklinks .quicklinks-txt a {
					font-size: 26px;
				}
				
			}
		}
	}
}/* ========-- End Media Query -- iPad1 -- --========= */

/* ========= Media Query min-width 1024 -- iPad2 -- ============= */
@media (min-width: 1024px) {
	.front .layout {
/*####### Product Button #######*/
		.block.block-block-product-finder.product-finder {
			margin-bottom: 40px;
		}
		.product-finder-btn.btn {
			margin: 40px auto;
		}
		.flexible-row .row {
			.block-title, .view-header  h3.block-title{
				margin: 24px 0 4px 0;
				text-align: left;
				font-size: 32px;
				font-family: $headingFont;
				font-weight: 200;
				color: $drab;
				text-transform: uppercase;
			}
		}
		.flexible-row .no-container.hero-block .row {
	/* ++##### HERO REGION 1024px ###### ++++++ */
			.l-col.col-md-12.l-region--hero-block {
	/* ++++++==|||=== CATS -(reg-HERO)- iPad2 1024px ++-- ===|||==+++  */				
		/*--++|||++--- Slider Block (homeHero) -- 1024 iPad2 --+(%)+(#4-- */
				.block.block-views-term-ref-categories-block-6 {	
					margin-bottom: 30px;
					padding-right: 0;
					padding-left: 0;
			
					.block-content .view.view-term-ref-categories.view-id-term_ref_categories.view-display-id-block_6.mainCats {
						.view-header {
							margin: auto;
							
							h3 a { 
								font-size: 20px; 
								font-weight: 100;
							}
						}
						.view-content {
							width: auto;
							margin: auto 1%;
							background-color: transparent;
							padding: 0 20px;

							.views-view-grid {
								grid: {
									column-gap: 12px;
								}
								padding:0;
								margin: auto;
								background-color: transparent;
								
								.views-grid-box.main-cats-top {
									.views-field-name {
										span.field-content {
											a {
												.logo-image {
													border-radius: 6px;
													
													img {
														border-radius: 6px;
														max-width: 100%;
														max-height: 120%;
													}
												}
											}
										}
									}
								}
								.views-grid-box.views-grid-box-2 a .cat-name {
									.fullTitle {
										display: none;
									}
									.shortTitle {
										display: block;
									}
								}
								.views-grid-box.views-grid-box-1.odd.first.row-first img {
									padding-top: 9%;
									padding-bottom: 9%;
								}
							}
						}
					}
				}/*- #### END term-ref-categories block 1024 iPad2 END END #### -*/
			}
		}   /* -= END HERO Region END -- (iPad2) -- ========= */
		
		/* -=++++++ BEGIN CONTENT Region -- 1024(iPad2) -+++- ===== */
		.flexible-row .container-fluid .row {
			.l-col.col-md-12.l-region--content {
				.block.block-views-home-page-slider-block-1 .block-content {
					.view-home-page-slider.home-shelving-system {			
						.view-content .item-list .field-content {
							
							.row.shelvingSystems-block {
								padding-right:2%;
								grid-template: {
									columns: auto 516px;
									rows: 368px;
								}
								.col-6:nth-child(1)  {
									img {
										
									}
								}
								.col-6:nth-child(2) {
									padding: 26px 30px;
									
									p {
										font-size: 19px;									
									}
									.text-line-1 p {
										font: {
											family: $headingFont;
											size: 19px;
											weight: 500;
										}
									}
									p.text-line-2 {
										font: {
											size: 48px;
											weight: 900;
										}
									}
									.button-link-wrap a {
										padding: 6px 60px 4px;
										width: auto;
									}
								}
							}
						}
					}
				}

		/* ++==|||==== Quicklinks -- 1024 (iPad2) -- ---+++++=======++ */
				.block-views-home-page-slider-block-2 .home-quicklinks {
					margin-bottom: 30px;
					
					.view-content {
						.views-view-grid.views-view-grid-cols-6 {
							grid: {
								template-columns: repeat(6, 1fr);
								column-gap: 2%;
								template-rows: auto;
							}
							max-width: 97vw;
							margin: auto;
							
							.views-grid-box {
								border: none;
								padding: 0;
								transition: all ease 0.4s;
								
								.quicklinks-item {
									grid-template: {
										columns: 1fr;
										rows: 80px 28px;
									}
									grid-row-gap: 8px;
									box-shadow: none;
									padding:0;
									
									.quicklinks-txt a {
										font: {
											family: $linkFont;
											weight: 400;
											size: 22px;
										}
										text: {
											align: center;
											decoration: none;
										}
										line-height:1.2;
										max-width:100%;
									}
									
									.views-view-grid::before {
										display: none;
									}
									
									.quicklinks-image {
										margin-bottom: 10px;
										background-color: transparent;
										
										a {
											box-shadow: 2px 2px 8px -2px #4a5356d1;;
											border-radius: 6px;
											width: 100%;
											padding: 5px;
											overflow: hidden;
											background: {
												color: transparent;
												image: radial-gradient(#ffffff5c, transparent);
											}
											transition: .8s ease;
											
											img {
												@include centerImg;
												height: auto;
												width: auto;
												padding: 4px 0;
											}
										}
										a::before {
											content: "";
											display: block;
											padding-bottom: 40%;	
										}
										a:hover, a:active {
											transition: .3s ease;
										}
									}
								}
								.quicklinks-item.ql-products {
									background-color: transparent;
									box-shadow: none;
			
									.quicklinks-image { 
										a {
											background: {
												color: $linkBlue1;
												image: radial-gradient(#7fc6f7f2, transparent);
											}
										}
										a:hover, a:active {
											background-color: $linkBlue;
										}
									}
								}
								.quicklinks-item.ql-lte {
									background-color: transparent;
									
									.quicklinks-image {
										a {
											background: {
												color: #7090a5 ;
												image: radial-gradient(#82c1e99c, transparent);
											}					
											
											img {
												margin-left:-2px;
											}
										}
										a:hover, a:active  {
											background-color: #142839;
										}
									}
									.quicklinks-txt a {
										font-size: 19px;
										padding-top: 5px;
									}
								}
								.quicklinks-item.ql-support {
									background-color: transparent;
									
									.quicklinks-image {
										a {
											background: {
												color: $sage;
												image: radial-gradient(#a8dee1, transparent);
											}
											
											
											img { 
											padding-top:7px;
											padding-bottom:0;
											}
										}
										a:hover, a:active {
											background-color: #83969b;
										}
									}
								}
								.quicklinks-item.ql-videos {
									background-color: transparent;
									
									.quicklinks-image {
										a {
											background: {
												color: $rustBtn;
												image: radial-gradient(#f5d29e, transparent);
											}
											
											img {
												padding-top:0;
												padding-bottom:2px;
											}
										}
										a:hover, a:active {
											background-color: #b58656;
										}
									}
								}
								.quicklinks-item.ql-green {
									background-color: transparent;
									
									.quicklinks-image {
										a {
											background: {
												color: $sage2;
												image: radial-gradient(#3fd383, transparent);
											}
										}
										a:hover, a:active {
											background: {
												color: #3a8f62;
											}
										}
									}
									.quicklinks-txt a {
										font-size: 19px;
										padding-top: 5px;
									}
								}
								.quicklinks-item.ql-contact {
									background-color: transparent;
									
									.quicklinks-image {
										a {
											background-color: $steelBluegreenLte;
										}
										a:hover, a:active {
											background-color: #6d899b;
										}
									}
								}
							}
						}
					}
				}
			} /* ------- END CONTENT region - END -------------- */
		} /* END .front .row END END END ----- */
	}
}/* ========-- End Media Query 1024 iPad2 --========= */
/* (0)(0)==## END media 1024 iPad2 END ##== */

/* -==++++++-=## media 1155 desktop ##=-+++++++==- */
@media (min-width: 1155px){
	.front .layout .flexible-row {
		.no-container.hero-block .row .l-col.col-md-12.l-region--hero-block {
			
			.block-views-term-ref-categories-block-6 {
				.view-term-ref-categories.view-id-term_ref_categories.view-display-id-block_6.mainCats {
					width: 94vw;
					margin: auto;
					
					.view-header {
						margin: auto;
					}
					.view-content {
						width:90%;
						margin: auto;
					}
				}
			}				
		}
	}
	
}/* (0)(0)==## END media 1155 desktop END ##== */
@media (min-width: 1440px){
	.front .layout .flexible-row {
		
		.no-container.hero-block {
			width: 1440px;
			margin: auto;
			.row .l-col.col-md-12.l-region--hero-block {
				 .block.block-views-term-ref-categories-block-6 .block-content {
					.view.view-term-ref-categories.view-id-term_ref_categories.view-display-id-block_6.mainCats {
						width: auto;

						.view-content {
							width:90%;
							margin: auto;
							
							.views-view-grid .views-grid-box.main-cats-top {
								.views-field-name span.field-content {
									a {
										.logo-image {
											height: 230px;
											width: 230px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.container-fluid.region_12 .row .l-col.col-md-12.l-region--content {
		    max-width: 1440px;
			margin: auto;
			
			.block.block-views-home-page-slider-block-2 .home-quicklinks .view-content {
				background-color: transparent;
			}
		}
	}
}/* (0)(0)==## END media 1440 desktop END ##== */
	
	
